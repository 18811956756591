exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-actions-jsx": () => import("./../../../src/pages/actions.jsx" /* webpackChunkName: "component---src-pages-actions-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-sanity-post-slug-current-jsx": () => import("./../../../src/pages/blog/{sanityPost.slug__current}.jsx" /* webpackChunkName: "component---src-pages-blog-sanity-post-slug-current-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrations-jsx": () => import("./../../../src/pages/integrations.jsx" /* webpackChunkName: "component---src-pages-integrations-jsx" */),
  "component---src-pages-learn-jsx": () => import("./../../../src/pages/learn.jsx" /* webpackChunkName: "component---src-pages-learn-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */)
}

